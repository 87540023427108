<template>
  <div class="Message wrapper">
    <Header></Header>
    <div class="mainContent" v-if="languageSelect==='zh'">
      <el-breadcrumb class="centerWidth" separator="/" replace>
        <el-breadcrumb-item :to="{ path: '/' }">油气人才</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/message' }"
          >消息中心</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ activeCrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-container class="centerWidth">
        <el-aside width="218px" height="auto">
          <div class="ViewName">消息中心</div>
          <el-menu
            :default-active="activeMessageName"
            class="el-menu-vertical-demo"
          >
            <el-menu-item
              v-for="(item, index) in messageNav"
              :key="index"
              :index="item.url"
              @click.native="handleSelectMenu(item.url)"
            >
              <span slot="title">
                {{ item.name }}
                <el-badge
                  v-if="item.unreadMessage"
                  class="mark"
                  :value="item.unreadMessage"
                  :max="99"
                />
              </span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </div>
    <div class="mainContent" v-if="languageSelect==='en'">
      <el-breadcrumb class="centerWidth" separator="/" replace>
        <el-breadcrumb-item :to="{ path: '/' }">Oil and gas information</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/message' }"
        >Message center</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ activeCrumbEn }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-container class="centerWidth">
        <el-aside width="218px" height="auto">
          <div class="ViewName">Message center</div>
          <el-menu
              :default-active="activeMessageName"
              class="el-menu-vertical-demo"
          >
            <el-menu-item
                v-for="(item, index) in messageNav"
                :key="index"
                :index="item.url"
                @click.native="handleSelectMenu(item.url)"
            >
              <span slot="title">
                {{ item.nameEn }}
                <el-badge
                    v-if="item.unreadMessage"
                    class="mark"
                    :value="item.unreadMessage"
                    :max="99"
                />
              </span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Message",
  components: { Header, Footer },
  data() {
    return {
      languageSelect:'',
      activeCrumb: "系统消息",
      activeCrumbEn: "System message",
      activeMessageName: "/message",
      messageNav: [
        {
          url: "/message",
          name: "系统消息",
          nameEn:'System message',
          unreadMessage: this.$store.state.MessageCount,
        },
        {
          url: "/subscriptionTheme",
          name: "订阅消息",
          nameEn:'Subscribe message',
          unreadMessage: 0,
        },
        {
          url: "/receivedlikes",
          name: "收到的赞",
          nameEn:'Likes',
          unreadMessage: 0,
        },
        {
          url: "/replyToMy",
          nameEn:'Response',
          name: "回复我的",

          unreadMessage: 0,
        },
      ],
    };
  },

  computed: {},
  created() {
    if (localStorage.getItem('language')) {
      this.languageSelect = localStorage.getItem('language')
    } else {
      this.languageSelect = 'zh'
    }
    this.activeMessageName = this.$route.path;
    let that = this;
    that.messageNav.forEach(function (item) {
      if (item.url == that.activeMessageName) {
        that.activeCrumb = item.name;
        that.activeCrumbEn = item.nameEn;
      }
    });
  },
  watch: {
    $route() {
      this.activeMessageName = this.$route.path;
      let that = this;
      that.messageNav.forEach(function (item) {
        if (item.url == that.activeMessageName) {
          that.activeCrumb = item.name;
          that.activeCrumbEn = item.nameEn;
        }
      });
    },
    "$store.state.MessageCount"(newValue) {
      this.messageNav[0].unreadMessage = newValue;
    },
  },
  methods: {
    handleSelectMenu(url) {
      if (url != this.$route.path) {
        // 在当前页面中调用 push 方法
        this.$router.push({ path: url });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.el-aside {
  background: url(../assets/messageBg.png) no-repeat right bottom, #fff;
  background-size: 172px 164px;
  padding-bottom: 164px;
  text-align: center;
  .ViewName {
    height: 64px;
    line-height: 64px;
    color: #333333;
    font-size: 20px;
    margin: 0 16px;
    border-bottom: 1px solid #ebebeb;
  }
  .el-menu {
    background: none !important;
    font-size: 16px;
    color: #333333;
    border: none !important;
    margin-top: 19px;
    .el-menu-item,
    .el-submenu__title {
      height: 64px;
      line-height: 64px;
      span {
        position: relative;
        .el-badge {
          position: absolute;
          left: calc(100% + 12px);
        }
      }
    }
    .el-menu-item.is-active {
      background: rgba(191, 0, 34, 0.1);
      color: var(--main-color);
      position: relative;
    }
    .el-menu-item.is-active::before {
      content: " ";
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      position: absolute;
      background: var(--main-color);
    }
    .el-menu-item:focus,
    .el-menu-item:hover {
      background: rgba(191, 0, 34, 0.1);
      color: var(--main-color);
      font-weight: 500;
    }
  }
}

.el-main {
  padding: 0;
  margin-left: 10px;
}
.el-container {
  align-items: flex-start;
}
</style>
